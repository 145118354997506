import React from "react"

export default function Birds() {
  return (
    <div className="birds-container">
      <div className="bird-container bird-container--one">
        <div className="bird bird--one" />
      </div>

      <div className="bird-container bird-container--two">
        <div className="bird bird--two" />
      </div>
    </div>
  )
}
